<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-25">
      <div class="font-20 text-333333">我的分享码</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item>我的分享码</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div class="purseBox_cont flex flex-column align-center justify-center">
      <div class="mb-10">
        <!-- <img
          :src="info.avatar"
          width="4.375rem"
          height="4.375rem"
          class="circle"
          alt=""
        /> -->
        <el-image
          style="width: 4.375rem; height: 4.375rem"
          :src="info.avatar"
          fit="cover"
        ></el-image>
      </div>
      <div class="mb-15">
        <div class="font-14 text-666666 mb-10 font-weight-bolder">我的分享邀请码</div>
        <div class="text-222222 mb-10 font-14">
          {{ info.username }}
        </div>
        <div class="font-14 mb-10">ID:{{ info.invitation_code }}</div>
      </div>
      <div class="mb-12">
        <!-- <img :src="info.img_code" width="7.0625rem" height="7.0625rem" alt="" /> -->
        <el-image
          style="width: 7.0625rem; height: 7.0625rem"
          :src="info.img_code"
          fit="cover"
        ></el-image>
      </div>
      <div class="font-14 text-999999">扫一扫二维码</div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      info: "",
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("center/getCode", {})
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          this.info = res.data;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
  &_cont {
    padding-top: 8.75rem;
    box-sizing: border-box;
    width: 27.3125rem;
    height: 34.1875rem;
    background-image: url("../../../assets/img/yqfind.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>
